// prefer default export if available
const preferDefault = m => m && m.default || m

exports.components = {
  "component---src-templates-adoc-js": () => import("/Users/angel/conceptsauce/syrup/packages/site/src/templates/adoc.js" /* webpackChunkName: "component---src-templates-adoc-js" */),
  "component---src-pages-404-js": () => import("/Users/angel/conceptsauce/syrup/packages/site/src/pages/404.js" /* webpackChunkName: "component---src-pages-404-js" */),
  "component---src-pages-cookbook-get-mdx": () => import("/Users/angel/conceptsauce/syrup/packages/site/src/pages/cookbook/get.mdx" /* webpackChunkName: "component---src-pages-cookbook-get-mdx" */),
  "component---src-pages-cookbook-is-product-in-basket-mdx": () => import("/Users/angel/conceptsauce/syrup/packages/site/src/pages/cookbook/isProductInBasket.mdx" /* webpackChunkName: "component---src-pages-cookbook-is-product-in-basket-mdx" */),
  "component---src-pages-cookbook-raw-data-mdx": () => import("/Users/angel/conceptsauce/syrup/packages/site/src/pages/cookbook/rawData.mdx" /* webpackChunkName: "component---src-pages-cookbook-raw-data-mdx" */),
  "component---src-pages-cookbook-what-products-were-viewed-but-not-purchased-mdx": () => import("/Users/angel/conceptsauce/syrup/packages/site/src/pages/cookbook/whatProductsWereViewedButNotPurchased.mdx" /* webpackChunkName: "component---src-pages-cookbook-what-products-were-viewed-but-not-purchased-mdx" */),
  "component---src-pages-index-mdx": () => import("/Users/angel/conceptsauce/syrup/packages/site/src/pages/index.mdx" /* webpackChunkName: "component---src-pages-index-mdx" */),
  "component---src-pages-sandbox-index-mdx": () => import("/Users/angel/conceptsauce/syrup/packages/site/src/pages/sandbox/index.mdx" /* webpackChunkName: "component---src-pages-sandbox-index-mdx" */)
}

exports.data = () => import(/* webpackChunkName: "pages-manifest" */ "/Users/angel/conceptsauce/syrup/packages/site/.cache/data.json")

